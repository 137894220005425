/* FormStyles.css */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap');

body {
  font-family: 'Nunito', sans-serif; /* Nunito is a close alternative to Avenir */
}
.form-input.error {
  border-color: #ff3333 !important;
  background-color: #fff6f6;
}

.styled-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.error-message {
  position: absolute;
  bottom: -20px;
  left: 20px;
  color: #ff3333;
  font-size: 12px;
}

.submit-button {
  margin-top: 30px;
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  border: none;
  background-color: #287B4C;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #1e5d3a;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-select.error .select__control {
  border-color: #ff3333 !important;
  background-color: #fff6f6;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.styled-form {
  width: 539px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #EFEFEF;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.form-group {
  margin-bottom: 20px;
}

.form-input,
.form-select {
  width: 100%;
  height: 61px;
  padding: 10px 32px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: #fff;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

.submit-button {
  width: 100%;
  height: 61px;
  padding: 10px 32px;
  font-size: 18px;
  color: #fff;
  background-color: #287B4C;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #38a567;
}

/* Custom styles for react-select */
.form-select .react-select__control {
  height: 61px;
  border-radius: 30px;
  border: 1px solid #ddd;
}

.form-select .react-select__value-container {
  height: 61px;
  padding: 0 32px;
}

.form-select .react-select__placeholder,
.form-select .react-select__single-value {
  display: flex;
  align-items: center;
  height: 100%;
}

.form-select .react-select__indicators {
  height: 61px;
}

.form-select .react-select__control:hover {
  border-color: #007bff;
}

.form-select .react-select__control--is-focused {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.form-select .react-select__menu {
  border-radius: 15px;
}

.form-select .react-select__option {
  padding: 10px 32px;
}

.form-select .react-select__option--is-focused {
  background-color: #f0f0f0;
}

.form-select .react-select__option--is-selected {
  background-color: #007bff;
}

@media (max-width: 600px) {
  .styled-form {
    width: 100%;
    padding: 20px 10px;
  }
}