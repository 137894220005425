@font-face {
    font-family: 'AvenirRegular';
    src: local('AvenirRegular'), url(./fonts/AvenirRegular.ttf) format("truetype");
  }
  @font-face {
    font-family: 'AvenirBlack';
    src: local('AvenirBlack'), url(./fonts/AvenirBlack.ttf) format("truetype");
  }
  @font-face {
    font-family: 'AvenirHeavy';
    src: local('AvenirHeavy'), url(./fonts/AvenirHeavy.ttf) format("truetype");
  }
  
  ::-webkit-scrollbar {
    width: 10px;
    height: 7px;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Slightly darker for better contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
}

  #root{
    background-color: #EFEFEF;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255); 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #287B4C; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #287B4C; 
  }